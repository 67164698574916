body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

#thq-main-body {
  padding-top: 1.25rem !important;
  padding-botom: 1.25rem !important;
}

#thq-sidebar-wrapper {
  min-height: 100vh;
  margin-left: -12rem;
  -webkit-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#thq-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper .list-group {
  width: 12rem;
}

#thq-content-wrapper {
  min-width: 100vw;
}

#thq-main-wrapper.toggled #thq-sidebar-wrapper {
  margin-left: 0;
}

/* @media (min-width: 768px) {
  #thq-sidebar-wrapper {
    margin-left: 0;
  }

  #thq-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #thq-main-wrapper.toggled #thq-sidebar-wrapper {
    margin-left: -12rem;
  }
} */

#thq-sidebar-wrapper #thq-sidebar-modules {
  padding: 1.25rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules p {
  font-size: 1rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules a {
  color: #999999;
  text-decoration: none;
}

#thq-main-wrapper .border-right {
  border-right: 1px solid #efefef !important;
}

.navbar-brand {
  display: inline !important;
}
.toggled .navbar-brand {
  display: none !important;
}

.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ef9a9a !important;
  background-image: none !important;
}

.is-invalid:focus, .form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

button a {
  color: #FFFFFF !important;
  text-decoration: none !important;
}

button {
  margin-right: 2px !important;
}

.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(left, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-image: linear-gradient(90deg, #f9f9f9 0px, #f4f4f4 40px, #f9f9f9 80px);
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 500px;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #007837;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

